const gallery_items = {
    'ADI': "images/ADI.jpg",
    'BD1': "images/BD1.jpg",
    'DB2': "images/DB2.jpg",
    'DC': "images/DC.jpg",
    'DUR': "images/DUR.jpg",
    'EGC': "images/EGC.jpg",
    'IPGC': "images/IPGC.jpg",
    'KAN-C': "images/KAN-C.jpg",
    'P2C': "images/P2C.jpg",
    'P3C': "images/P3C.jpg",
    'P4C': "images/P4C.jpg",
    'P5C': "images/P5C.jpg",
    'P6C': "images/P6C.jpg",
    'P7C': "images/P7C.jpg",
    'P8C': "images/P8C.jpg",
    'P9GW': "images/P9GW.jpg",
    'P10C': "images/P10C.jpg",
    'P11C': "images/P11C.jpg",
    'P12C': "images/P12C.jpg",
    'P13C': "images/P13C.jpg",
    'P14C': "images/P14C.jpg",
    'P15C': "images/P15C.jpg",
    'P16C': "images/P16C.jpg",
    'P17C': "images/P17C.jpg",
    'P18C': "images/P18C.jpg",
    'P19C': "images/P19C.jpg",
    'P20C2': "images/P20C2.jpg",
    'P21C': "images/P21C.jpg",
    'P22C': "images/P22C.jpg",
    'PIC': "images/PIC.jpg",
    'PJK': "images/PJK.jpg",
    'PRW': "images/PRW.jpg",
    'RPC': "images/RPC.jpg",
    'SGC': "images/SGC.jpg",
    'TAR': "images/TAR.jpg",
}

export default gallery_items