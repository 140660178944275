const eventData = [
    {
        "Code": "FF01",
        "Place": "Kamalpur",
        "Date": "3 December, 1971",
        "Target": "To trap the Pak troops entrenched Kamalpur",
        "Event": "-Allied forces by-passed Kamalpur",
        "Team": "Allied Forces",
        "PakLeader": "Capt. Ahsan Malik"
    },
    {
        "Code": "FF02",
        "Place": "Kamalpur",
        "Date": "4 December, 1971",
        "Event": "-Pak company Surrendered",
        "Time": "6:00 PM"
    },
    {
        "Code": "FF03",
        "Place": "Khosalpur",
        "Date": "4 December, 1971",
        "Target": "To Block Bakshiganj-Sherpur Road",
        "Event": "-Established road block",
        "Time": "By Dawn",
        "Team": "Allied Forces"
    },
    {
        "Code": "FF04",
        "Place": "Jankipur",
        "Date": "4 December, 1971",
        "Target": "To Block Bakshiganj-Jamalpur Road",
        "Event": "-Made outflanking move and established a road block.",
        "Team": "Freedom Fighters"
    },
    {
        "Code": "FF05",
        "Place": "Bakshiganj",
        "Date": "5 December, 1971",
        "Event": "-Pak Army pulled out of Bakshiganj",
        "Time": "At night"
    },
    {
        "Code": "FF06",
        "Place": "Bagher Char",
        "Date": "6 December, 1971",
        "Target": "To cross Brahmaputra",
        "Event": "-Pakistanis blowed up a bridge after a resolute fight"
    },
    {
        "Code": "FF07",
        "Place": "Sherpur",
        "Date": "6 December, 1971",
        "Target": "To raise Bangladesh flag at newly liberated Sherpur",
        "Event": "-Area Commander Captain Abdul Aziz raised Bangladesh flag",
        "Team Leader": "Captain Abdul Aziz"
    },
    {
        "Code": "FF08",
        "Place": "Jamalpur",
        "Date": "7 December, 1971",
        "Target": "To cross the Brahmaputra",
        "Event": "-The allied forces and freedom fighters both crossed the Brahmaputra together.",
        "Time": "At nightfall",
        "Team": "Allied Forces, Freedom Fighters"
    },
    {
        "Code": "FF09",
        "Place": "Bapli",
        "Date": "7 December, 1971",
        "Event": "-Established base at Bapli, Southeast Jamalpur",
        "Time": "2:30 AM",
        "Column11": "Not Found"
    },
    {
        "Code": "FF10",
        "Place": "Mollapara",
        "Date": "8/9 December, 1971",
        "Target": "To cut Jamalpur-Tangail Road",
        "Event": "-Established a road block",
        "Time": "At night"
    },
    {
        "Code": "FF11",
        "Place": "Sherpur",
        "Date": "9 December, 1971",
        "Target": "To attack Jamalpur",
        "Event": "-Lt. Gen. Aurrora arrived at Sherpur",
        "Time": "3:00 PM",
        "Team": "Allied Forces, Freedom Fighters",
        "Team Leader": "Lt. Gen. Aurrora",
        "PakLeader": "Lt. Col. Sultan Ahmed"
    },
    {
        "Code": "FF12",
        "Place": "Jamalpur",
        "Date": "10 December, 1971",
        "Target": "To free Jamalpur",
        "Event": "-A heavy attack started with 2 air attack",
        "Team": "Allied Forces, Freedom Fighters",
        "PakLeader": "Major Fazle Akbar"
    },
    {
        "Code": "FF13",
        "Place": "Jamalpur",
        "Date": "11 December, 1971",
        "Target": "To accept formal surrender",
        "Event": "-General Nagra accept the formal surrender of Jamalpur Garrison",
        "Time": "7:00 AM",
        "Team Leader": "General Nagra"
    },
    {
        "Code": "FF14",
        "Place": "Pongli",
        "Date": "11 December, 1971"
    }
]

export default eventData